/* Default Light Theme */
:root {
  --body-bg: #f8f9fa;
  --body-bg-gradient: radial-gradient(circle at top center, rgba(200, 220, 255, 0.4), transparent 70%);

  --text-primary: #000;
  --text-link: #024CBB;

  --card-bg: linear-gradient(180deg, #fff 0%, rgba(255, 255, 250, .5) 100%);
  --card-back-bg: linear-gradient(180deg, #e4e4e9 0%, rgba(240, 240, 235, 0.5) 100%);
  --card-border-color: rgba(0, 0, 0, 0.1);
  --card-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.075);

  --badge-bg: #000;
  --badge-color: #fff;
  --badge-border: none;

  --icon-color:  rgba(0, 0, 0, 0.5);

  --muted-txt: rgba(0, 0, 0, 0.8);
  --muted-line:  rgba(0, 0, 0, 0.1);

  --form-label: black;

  --border-mute: rgba(0, 0, 0, 0.1);
  --bg-mute: linear-gradient(180deg, rgba(255, 255, 255, .5) 0%, rgba(255, 255, 255, 0) 100%);

  --animate:80ms;

  --card-link-color: rgba(0, 0, 0, 0.4);
}

/* Dark Theme */
[data-theme="dark"] {

  --body-bg: #0B0418;
  --body-bg-gradient: radial-gradient(circle at top center, rgba(48, 43, 99, 0.8), transparent 70%);

  --text-primary: #fff;
  --text-link: #6DA9FD;

  --card-bg: linear-gradient(180deg, #2e2b4f 0%, rgba(46, 43, 79, 0) 100%);
  --card-back-bg: linear-gradient(180deg, #2e2b4f 0%, rgba(46, 43, 79, .5) 100%);
  --card-border-color:  rgba(255, 255, 255, 0.175);
  --card-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  --badge-bg:  rgba(255, 255, 255, .1);
  --badge-color: #fff;
  --badge-border: 1px solid rgba(255, 255, 255, 0.4);

  --icon-color:  rgba(255, 255, 255, 0.5);

  --muted-txt: rgba(255, 255, 255, 0.5);
  --muted-line:  rgba(255, 255, 255, 0.175);
  
  --border-mute: rgba(255, 255, 255, 0.175);
  --bg-mute: linear-gradient(180deg, rgba(48, 43, 99, .3) 0%, rgba(48, 43, 99, 0) 100%);

  --card-link-color: rgba(255, 255, 255, 0.6);

}

/* General Body Styles */
body {
  background-color: var(--body-bg) ;
  background-image:var(--body-bg-gradient);
  background-repeat: no-repeat;
  background-size: 100% ;
  height: 100vh;
  color: var(--text-primary);
  transition:color var(--animate) ease-in-out, background var(--animate) ease-in-out;
}



h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
}

header h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
}


h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.25rem;
}


h4 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
}

h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: .75rem;
}


footer a {
  color: var(--text-primary);
}

footer a:hover {
  color: var(--text-primary);
}

footer .list-inline-item:not(:last-child)::after {
  content: "|";
  margin-left: 12px
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Link Styles */
a {
  color: var(--text-link);
  text-decoration: none;
}

a:hover {
  color: var(--text-link);
  text-decoration: underline;
}


.form-check-input:checked {
  border-color: rgba(255, 255, 255, 0.1);
  background-color:rgba(255, 255, 255, 0.3);
}

.toggle {
  font-size: 1.25rem;
  cursor: pointer;
  color: 	 var(--text-primary);
  margin-left: 6px;
}


/* Card Styles */
.card {
  background: var(--card-bg);
  color: var(--text-primary);
  border-color: var(--card-border-color); 
  box-shadow: var(--card-box-shadow);


}

.react-card-front .card {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 1400px) {
  .homepage .card {
    min-width: 424px;
  }
}

.card {
  overflow: hidden; /* Ensures image stays inside the card */
  position: relative;
}

.card-img-container {
  width: 100%;
  height: 200px; /* Fixed height to prevent shifting */
  overflow: hidden;
  position: relative; /* Ensures child elements are positioned correctly */
}

.card-img-top {
  transition: transform 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Apply hover effects only when on the front side */
.react-card-front .card:hover .card-img-top,
.react-card-front .card-img-container:hover .card-img-top {
  transform: scale(1.1);
}

/* Ensure the entire image is clickable */
.image-link {
  display: block;
  width: 100%;
  height: 100%;
}

/* Underline link when hovering over image or the link itself, only on the front */
.react-card-front .card:hover .card-body a,
.react-card-front .card-body a:hover {
  text-decoration: underline;
  text-decoration-color: var(--card-link-color);
  text-decoration-thickness: 1px;
  text-underline-offset: 1.5px;
}



.card h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25rem;
}


.card p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.card .author {
  font-size:  0.875rem;
}


.card span {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;

}

.card a  {
  color: var(--text-primary); 
  text-decoration: none;
}

.card a:hover {
  color: var(--text-primary); 
  text-decoration: underline;
}

.react-card-back .card {
  background: var(--card-back-bg);
}

.icons {
  font-size: 1.75rem;
  cursor: pointer;
  color: var(--icon-color);
 }
 
 .icons:hover, 
 .icons:active {
  color: var(--text-primary);
  }
  

/* Badge Styles */
.badge {
  background-color: var(--badge-bg);
  color: var(--badge-color);
  border: var(--badge-border);
  border-radius: .375rem .375rem .375rem 0;
}

.logo {
  position: relative;
}

.logo:after {
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  background: inherit;
  filter: blur(20px);
  z-index: -1;
}

.logo.light {
  filter: invert(0); /* Original color */
}


.logo.dark {
  filter: invert(1) drop-shadow(0 0 10px rgba(255, 255, 255, 0.2)) 
                  drop-shadow(0 0 20px rgba(255, 255, 255, 0.3)) 
                  drop-shadow(0 0 30px rgba(255, 255, 255, 0.2));
  animation: flickerGlow 4s infinite alternate;
}

@keyframes flickerGlow {
  0% {
    filter: invert(1) drop-shadow(0 0 8px rgba(255, 255, 255, 0.2))
                    drop-shadow(0 0 18px rgba(255, 255, 255, 0.25))
                    drop-shadow(0 0 28px rgba(255, 255, 255, 0.2));
  }
  50% {
    filter: invert(1) drop-shadow(0 0 12px rgba(255, 255, 255, 0.3))
                    drop-shadow(0 0 22px rgba(255, 255, 255, 0.35))
                    drop-shadow(0 0 32px rgba(255, 255, 255, 0.3));
  }
  100% {
    filter: invert(1) drop-shadow(0 0 8px rgba(255, 255, 255, 0.2))
                    drop-shadow(0 0 18px rgba(255, 255, 255, 0.25))
                    drop-shadow(0 0 28px rgba(255, 255, 255, 0.2));
  }
}



form label {
  color: var(--form-label);
}

.border-mute {
  border-color: var(--border-mute) !important;
  background: var(--bg-mute);
}





/* Start Dashboard */


.dashboard h2 {
  font-size: 1.25rem;

}

@media (min-width: 768px) {
  .dashboard h2  {
    font-size: 1.5rem;
}
}


.temp {
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.conditions {

  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.current,
.forecast {
  background: rgb(142,176,217);
  background: linear-gradient(0deg, rgba(142,176,217,1) 0%, rgba(76,110,166,1) 100%);
}


.current.partly-cloudy-day {
  background-image: linear-gradient(rgba(78,115, 171, .5), rgba(78,115, 171, .9)), url(/public/images/partly-cloudy-day-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.current.partly-cloudy-night {
  background-image: linear-gradient(rgba(0,0, 0, .5), rgba(47,79, 79, .9)), url(/public/images/partly-cloudy-night-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}


.current.cloudy {
  background-image: linear-gradient(rgba(78,115, 171, .5), rgba(78,115, 171, .9)), url(/public/images/cloudy-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.current.rain {
  background-image: linear-gradient(rgba(78,115, 171, .5), rgba(78,115, 171, .9)), url(/public/images/rain-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.current.clear-day {
  background-image: linear-gradient(rgba(78,115, 171, .5), rgba(78,115, 171, .9)), url(/public/images/clear-day-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}



.datetime {
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
