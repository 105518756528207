.article-item {
    display: flex;
    justify-content: space-between; /* Pushes date to the right */
    align-items: center; /* Vertically centers items */
    border-bottom: 1px solid var(--muted-line);
    padding: 0.5rem 0; /* Adds vertical padding */
  }
  
  .article-content {
    display: flex;
    flex-direction: column; /* Stacks link and author vertically */
  }
  
  .article-title {
    text-decoration: none; /* Remove underline */
    font-weight: bold;
    color: var(--text-link);
  }
  
  .article-title:hover {
    text-decoration: underline; /* Add underline on hover */
  }
  
  .article-author {
    font-size: 1rem;
    color: var(--muted-txt);
  }
  
  .article-time {
    font-size: 0.9rem;
    color: var(--muted-txt);
  }
  